<template>
    <div class="row">
        <div class="col-md-12">
          <h2 class="pb-3">Einstellungen - Trainer</h2>
              <div class="alert alert-warning mb-4" role="alert" v-if="username == 'darmstadt@einweisungsapp.de' || username == 'neroberg@einweisungsapp.de'">
              Die heutigen Trainer werden täglich gegen 8:00 Uhr aus KET geladen. Manuelle Änderungen werden hierbei überschrieben!
            </div>
            <table class="table table-striped mt-3">
                <thead>
                    <tr>
                        <th>Name des Trainers</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="trainer in trainers" :key="trainer.key">
                        <td class="align-middle">{{ trainer.trainerName }}</td>
                        <td class="align-middle"><button type="button" class="btn btn-danger ml-2" @click.prevent="removeTrainer(trainer.key)" data-toggle="tooltip" data-placement="top" title="Trainer löschen"><i class="bi bi-trash"></i></button></td>
                    </tr>
                    <tr>
                      <td class="align-middle"><input type="text" class="form-control" id="newTrainerName" placeholder="Trainer hinzufügen..." v-model="newTrainerName" v-on:keyup.enter="addTrainer(newTrainerName)" minlength="3" maxlength="20"></td>
                      <td class="align-middle"><button type="button" class="btn btn-primary ml-2" @click.prevent="addTrainer(newTrainerName)" data-toggle="tooltip" data-placement="top" title="Trainer hinzufügen"><i class="bi bi-plus-square"></i></button></td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-warning text-center" role="alert" v-if="trainers.length < 1">
              Keine Trainer gefunden!
            </div>

            </div>
    </div>
</template>

<script>
import { db } from '../firebaseDb'
import store from "../store"
export default {
  name: "app",
  components: {
  },
  data () {
    return {
      trainers: [],
      newTrainerName: '',
      username: store.getters.user.data.email
    }
  },
  created () {
      const trainerRef = db.collection('trainer')
      trainerRef.where('uid', '==', store.getters.user.data.uid).orderBy('trainerName').onSnapshot((snapshotChange) => {
      this.trainers = []
      snapshotChange.forEach((doc) => {
        this.trainers.push({
          key: doc.id,
          trainerName: doc.data().trainerName
        })
      })
    })
  },
  methods: {
    removeTrainer: function (trainerid) {
      if (window.confirm("Diesen Trainer löschen?")) {
        console.log("Remove trainer " + trainerid)
        db.collection('trainer').doc(trainerid).delete().then(() => {
          this.$toast.success("Trainer wurde erfolgreich entfernt.")
        }).catch(() => {
          this.$toast.error("Fehler beim Entfernen des Trainers.")
        })
      }
    },
    addTrainer: function (trainername) {
      if (trainername !== '') {
        console.log("Add trainer " + trainername)
        db.collection('trainer').add({
          trainerName: trainername,
          uid: store.getters.user.data.uid
        }).then(() => {
          this.$toast.success("Trainer wurde erfolgreich hinzugefügt.")
        }).catch(() => {
          this.$toast.error("Fehler beim Hinzufügen des Trainers.")
        })
        this.newTrainerName = ''
      }
    }
  },
  computed: {
}
}

</script>
